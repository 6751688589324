
















































































































































































import { Service } from '@app/models';
import { debounce } from '@bcase/core';
import { Module, ModuleSettings, Reference } from '@bcase/module-editor';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCheckSquare,
  faEdit,
  faPlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import { licenses } from '../data/licenses';
import { ModuleModule } from '../store/modules/module-module';
import { downloadJson, readJson } from '../utils/download';

library.add(faCheckSquare, faEdit, faPlus, faTrash);

@Component
export default class Editor extends Vue {
  public filter: string | null = null;
  public filterLicense: string | null = null;
  private module = getModule(ModuleModule);

  public get fields() {
    return [
      {
        key: 'active',
        label: this.$t('active'),
        sortable: false,
        thClass: 'th-active',
        tdClass: 'td-active',
      },
      {
        key: 'name',
        label: this.$t('module'),
        sortable: true,
        thClass: 'th-name',
        tdClass: 'td-name',
      },
      {
        key: 'category',
        label: this.$t('category'),
        sortable: true,
        thClass: 'th-category',
        tdClass: 'td-category',
      },
      this.modules.some(m => !!m.price) && {
        key: 'price',
        label: this.$t('price'),
        sortable: true,
        thClass: 'th-price',
        tdClass: 'td-price',
      },
      {
        key: 'order',
        label: this.$t('order'),
        sortable: true,
        thClass: 'th-order',
        tdClass: 'td-order',
      },
      {
        key: 'actions',
        label: '',
        sortable: false,
        thClass: 'th-actions',
        tdClass: 'td-actions',
      },
    ];
  }

  public get licenses() {
    return licenses;
  }

  public get modules() {
    return this.module.all
      .filter(module => {
        return this.filter
          ? module.category
              .split('+')
              .some(category => this.filter === category)
          : true;
      })
      .filter(module => {
        return this.filterLicense
          ? module.metadata.license.indexOf(this.filterLicense) >= 0
          : true;
      })
      .map(module => this.toItem(module));
  }

  public get serviceFields() {
    return [
      {
        key: 'active',
        label: this.$t('active'),
        formatter: (value: boolean) => this.$t(value),
        sortable: false,
        tdClass: 'td-active',
        thClass: 'th-active',
      },
      {
        key: 'name',
        label: this.$t('name'),
        thClass: 'th-name',
        tdClass: 'td-name',
      },
      {
        key: 'price',
        label: this.$t('price'),
        sortable: true,
        thClass: 'th-price',
        tdClass: 'td-price',
      },
      {
        key: 'order',
        label: this.$t('order'),
        sortable: true,
        thClass: 'th-order',
        tdClass: 'td-order',
      },
      {
        key: 'actions',
        label: '',
        sortable: false,
        thClass: 'th-actions',
        tdClass: 'td-actions',
      },
    ];
  }

  public get services() {
    return this.module.services;
  }

  public get targets() {
    return this.$FEATURES.target;
  }

  public get updaters() {
    const m = this.modules.reduce<{ [id: string]: Function }>((acc, module) => {
      acc[module.id] = debounce(this.update.bind(this, module), 2000);
      return acc;
    }, {});

    const s = this.services.reduce<{ [id: string]: Function }>(
      (acc, service) => {
        acc[service.id] = debounce(this.updateService.bind(this, service), 500);
        return acc;
      },
      {}
    );

    return { ...m, ...s };
  }

  created() {
    this.module.load();
  }

  public async activateModule(item: Item) {
    this.module.activate(item);
  }

  public async activateService(service: Service) {
    await this.$firebase.doc(`service/${service.id}`).update('active', true);
  }

  public async addModule() {
    if (!this.$user.data) return;

    const user = this.$user.data;
    const { id, version } = await this.module.createModule(user.uid);
    this.$router.push(`/editor/${id}@${version}`);
  }

  public async deactivateModule(item: Item) {
    this.module.deactivate(item);
  }

  public async deactivateService(service: Service) {
    await this.$firebase.doc(`service/${service.id}`).update('active', false);
  }

  public async deleteModule(item: Item) {
    if (!item.settings.locked) await this.module.deleteModule(item);
  }

  public async deleteService(service: Service) {
    await this.$firebase.doc(`service/${service.id}`).delete();
  }

  public async devExportModules() {
    const items = new Map<string, any>();

    const modules = await this.$firebase
      .col('module')
      .get()
      .then(snap => this.$firebase.toData<any>(snap));

    for (const module of modules) {
      const id = `${module.id}@${module.version}`;
      items.set(`module/${id}`, module);

      const blocks = await this.$firebase
        .col(`module/${id}/block`)
        .get()
        .then(snap => this.$firebase.toData<any>(snap));

      for (const block of blocks)
        items.set(`module/${id}/block/${block.id}@${block.version}`, block);
    }

    downloadJson((Object as any).fromEntries(items), 'modules.json');
  }

  public async devImportModules() {
    // Read JSON & confirm before replacing
    const data = await readJson();
    const confirm = await this.$bce.confirm(
      'Let op!',
      'De huidige modules worden verwijderd, weet je zeker dat je door wilt gaan?',
      { cancel: 'Annuleren', ok: 'Vervang Modulen' }
    );
    if (!confirm) return;

    // Delete current modules
    const batch = this.$firebase.batch();
    const modules = await this.$firebase
      .col('module')
      .get()
      .then(snap => this.$firebase.toData<any>(snap));

    for (const module of modules) {
      const id = `${module.id}@${module.version}`;
      batch.delete(this.$firebase.doc(`module/${id}`));

      const blocks = await this.$firebase
        .col(`module/${id}/block`)
        .get()
        .then(snap => this.$firebase.toData<any>(snap));

      for (const block of blocks) {
        const path = `module/${id}/block/${block.id}@${block.version}`;
        batch.delete(this.$firebase.doc(path));
      }
    }

    // Set imported modules
    for (const path of Object.keys(data)) {
      const ref = this.$firebase.doc(path);
      batch.set(ref, data[path]);
    }

    await batch.commit();
  }

  public async duplicateModule(item: Item) {
    if (!this.$user.data) return;

    const user = this.$user.data.uid;
    await this.module.duplicate({ ref: item, user });
    await this.$firebase.admin.topic('generate-module-question');
  }

  public editModule(item: Item) {
    if (item.settings.locked) return;
    else this.$router.push(`/editor/${item.id}@${item.version}`);
  }

  public editService(service: Service) {
    this.$router.push(`/editor/service/${service.id}`);
  }

  private async update(ref: Reference, key: string, value: any) {
    const module = this.module.find(ref);
    if (!module) return;

    await this.module.updateModule({
      ...module,
      metadata: { ...module.metadata, [key]: value },
    });

    this.$bce.message(this.$t('changes-saved'), 1, 'primary');
  }

  private async updateService(service: Service, key: string, value: any) {
    await this.$firebase.doc(`service/${service.id}`).update(key, value);
  }

  private toItem(module: Module): Item {
    const category = module.category
      ? module.category
          .split('+')
          .map(v => this.$t('target-audience-' + v))
          .join(' & ')
      : '';

    const metadata = !!module.metadata;

    return {
      _rowVariant: metadata && module.metadata.fsq ? 'alt' : undefined,
      active: this.$t(module.settings.active),
      category,
      fsq: metadata && !!module.metadata.fsq,
      id: module.id,
      name: module.name,
      order: (metadata && module.metadata.order) || 0,
      price: (metadata && module.metadata.price) || 0,
      settings: module.settings,
      version: module.version,
    };
  }
}

interface Item {
  readonly _rowVariant?: string;
  readonly active: string;
  readonly category: string;
  readonly fsq: boolean;
  readonly id: string;
  readonly name: string;
  readonly order: number | undefined;
  readonly price: number;
  readonly settings: ModuleSettings;
  readonly version: string;
}
