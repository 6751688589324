export interface License {
  readonly key: string;
  readonly name: string;
  readonly price: number;
  readonly description: string;
  readonly options: LicenseOption[];
  readonly featured?: boolean;
}

export interface LicenseOption {
  readonly icon: string;
  readonly text: string;
  readonly tooltip: string;
}

export const licenses: License[] = [
  {
    key: 'free',
    name: 'license-free',
    price: 0,
    description: 'license-free-description',
    options: [
      {
        icon: 'license-free-1-icon',
        text: 'license-free-1',
        tooltip: 'license-free-1-tooltip',
      },
      {
        icon: 'license-free-2-icon',
        text: 'license-free-2',
        tooltip: 'license-free-2-tooltip',
      },
      {
        icon: 'license-free-3-icon',
        text: 'license-free-3',
        tooltip: 'license-free-3-tooltip',
      },
    ],
  },
  {
    key: 'basic',
    name: 'license-basic',
    price: 1450,
    description: 'license-basic-description',
    options: [
      {
        icon: 'license-basic-1-icon',
        text: 'license-basic-1',
        tooltip: 'license-basic-1-tooltip',
      },
      {
        icon: 'license-basic-2-icon',
        text: 'license-basic-2',
        tooltip: 'license-basic-2-tooltip',
      },
      {
        icon: 'license-basic-3-icon',
        text: 'license-basic-3',
        tooltip: 'license-basic-3-tooltip',
      },
    ],
    featured: true,
  },
  {
    key: 'plus',
    name: 'license-plus',
    price: 4400,
    description: 'license-plus-description',
    options: [
      {
        icon: 'license-plus-1-icon',
        text: 'license-plus-1',
        tooltip: 'license-plus-1-tooltip',
      },
      {
        icon: 'license-plus-2-icon',
        text: 'license-plus-2',
        tooltip: 'license-plus-2-tooltip',
      },
    ],
  },
  {
    key: 'pro',
    name: 'license-pro',
    price: 6200,
    description: 'license-pro-description',
    options: [
      {
        icon: 'license-pro-1-icon',
        text: 'license-pro-1',
        tooltip: 'license-pro-1-tooltip',
      },
      {
        icon: 'license-pro-2-icon',
        text: 'license-pro-2',
        tooltip: 'license-pro-2-tooltip',
      },
    ],
  },
];
